@import url(https://fonts.googleapis.com/css?family=Bad+Script|Great+Vibes|Josefin+Sans|Josefin+Slab|Neucha|Sacramento&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round|Material+Icons+Two+Tone);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-icons-two-tone.md-18 { font-size: 18px; }
.material-icons-two-tone.md-24 { font-size: 24px; vertical-align: bottom}
.material-icons-two-tone.md-28 { font-size: 28px; }
.material-icons-two-tone.md-32 { font-size: 32px; }
.material-icons-two-tone.md-36 { font-size: 36px; }
.material-icons-two-tone.md-48 { font-size: 48px; }
.material-icons-round.md-48 { font-size: 48px; }



.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.backImgContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.backImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
}

.App-link {
  color: #61dafb;
}

hr {
  border: 1px solid black;
  width: 20%;
}

h1 {
  font-family: 'Bad Script', 'Neucha', 'Sacramento', 'Great Vibes', cursive;
  font-size: 54px;
}

h2 {
  font-family: 'Josefin Slab', serif;
  font-size: 24px;
}

p {
  font-family: 'Josefin Slab', serif;
  font-size: 18px;
}

p.bold {
  font-weight: bold;
}

.centerText {
  text-align: center;
}

.pButton {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

iframe {
  border: 0;
}

.card {
  /*fffcf0*/
  background-color: rgba(255, 252, 240, 0.96);
  width: 80%;
  max-width: 700px;
  padding: 10px;
  border: 0px solid #487eb0;
  border-radius: 2px;
  margin: auto;
  margin-bottom: 80px;
  padding-bottom: 40px;
  box-shadow: 0px 6px 15px -5px black;
}

.splash {
  position: absolute;
  width: 100%;
  top: 80%;
  -webkit-animation: 1s ease-out 0.0001s 1 up;
          animation: 1s ease-out 0.0001s 1 up;
}

button {
  background-color:white;
  color: black;
  border: 1px solid black;
  border-radius: 0px;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-family: 'Josefin Slab', serif;
  font-weight: bold;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: auto;
  display: block;
  align-self: center;
}

button:hover {
  box-shadow: 0px 4px 10px -5px black;
}

.galleryButton {
  position: fixed;
  left: 0px;
  top: 0px;
  padding: 15px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  color: whitesmoke;
  opacity: 0.7;
}
.galleryButton:hover {
  opacity: 1.0;
}

.RSVP {
  width: 80%;
  max-width: 400px;
  text-align: left;
  align-self: center;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  padding-top: 10px;
  background-color:white;
  border: 1px solid black;
  box-shadow: 0px 4px 10px -5px black;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid black;
  vertical-align: baseline;
}

.checkmarkBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid black;
  vertical-align: baseline;
}

.checkmarkBox:after {
  content: "";
  position: absolute;
  display: none;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
  box-shadow: 0px 4px 10px -5px black;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmarkBox:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: black;
}

/* Style the indicator (dot/circle) */
.container .checkmarkBox:after {
  top: 9px;
 left: 9px;
 width: 8px;
 height: 8px;
 border-radius: 5%;
 background: black;
}

.inputBox{
  width: 90%;
  max-width: 400px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 12px;
  border: 2px solid Black;
  color: #000000;
  resize: vertical;
  font-size: 18px;
  text-align: left;
  margin: auto;
  margin-bottom:15px;
  display: block;
  align-self: center;
}

.inputBox:hover {
  box-shadow: 0px 4px 10px -5px black;
  border: 2px solid white;
}

.heartbeat:hover {
  cursor: pointer;
  -webkit-animation: 1s ease-out 0.1s infinite heartbeat;
          animation: 1s ease-out 0.1s infinite heartbeat;
}

.fadeIn {
  -webkit-animation: 2s ease-out fadeIn;
          animation: 2s ease-out fadeIn;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes up {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  50% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes up {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  50% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes fadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
  15% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  30% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);;
  }
  45% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);;
  }
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);;
  }
}

@keyframes heartbeat {
  0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
  15% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  30% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);;
  }
  45% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);;
  }
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);;
  }
}



